<template>
<v-app>
  <v-main class="grey lighten-4 pt-0 pl-0">
  
  <v-container> 
    <div style="text-align:center">
    <h2>MyTrustedCloud.io</h2>
    <p>Experience web3 decentralized cloud storage</p>     
    <a href="https://mytrustedcloud.io"><img width="50" class="center" :src="require('@/assets/logomtc4.png')"></a>
    <v-spacer></v-spacer>
    <v-spacer></v-spacer>
    <h1><b>A share is available for you during {{duration}} !</b></h1>
    </div> 

    <div><br></div>
    <!-- Breadcrumbs -->
        <v-row v-if="breadcrumbs_show" no-gutters>
            
            <v-icon size="30" class="ml-5" color="indigo">
                {{'mdi-folder-open amber--text'}}        
            </v-icon>
            <v-breadcrumbs class="pl-3" :items="breadcrump" divider=">">
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item
                  class="row-pointer indigo--text"
                  @click.native="openClick(item)"
                  >
                    {{ item.name }}
                  </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-row>

    <!-- Explore level 2 - choose exploration type: list or grid -->
      <v-col md="12" class="text-left ml-2" v-show="show_level2_explore">
        <v-icon size="36" color="indigo" right @click="viewList()">
        mdi-view-list
        </v-icon>
        <v-icon size="40" color="indigo" right @click="viewGrid()">
        mdi-view-module
        </v-icon>
      </v-col>

    <!-- Show Level 2 list -->
      <div v-show="show_level2_list">
      <v-col md="12">
      <v-data-table
        :headers="getHeaders()"
        :items="collection_level2"
        @click:row="openClick"
        mobile-breakpoint="0"
        class="transparent row-pointer"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-icon x-large v-if="!item.file" @click="openClick(item)">
          {{'mdi-folder amber--text'}}
          </v-icon>
          <div v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'"
          v-show="progresscircular" 
          class="content-center, image-icon">
          <v-progress-circular
            indeterminate
            :size="20"
            color="blue-grey lighten-4"
          ></v-progress-circular>
          </div>
          <v-img v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'"
          class="image-icon"
          @loadstart="onImgloadStart"
          @load="onImgload"
          contain
          max-height="40"
          max-width="40"
          @click="openClick(item)"
          :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+item.key+'?download'"
          ></v-img>
          <v-icon x-large v-else @click="openClick(item)">
          {{ file_mdi[item.file]}}
          </v-icon>
          <a v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'"
          >&nbsp;</a>
          <a style="color:#000000" @click="openClick(item)">{{ item.name }}</a>
        </template>
        <template v-slot:[`item.file`]="{ item }">
              <td class="hidden-sm-and-down text-align-center">{{ item.file }}</td>
          </template> 
          <template v-slot:[`item.size`]="{ item }">
               {{ FileConvertSize(item.size) }}
          </template> 
          <template v-slot:[`item.created`]="{ item }">
              <td class="hidden-sm-and-down text-align-center">{{ item.created }}</td>
          </template> 
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon v-if="item.type === 'file'" :href="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+item.key+'?download'" target="_blank">
            <v-icon small>mdi-download</v-icon>
          </v-btn>
          <v-btn icon disabled v-else>
            <v-icon small>mdi-download</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      </v-col>
      </div>
      
    <!-- Show Level 2 grid -->
      <div v-show="show_level2_grid" class="ml-5">
        <v-row class="mt-4" >
        <v-col md="2" v-for="item in collection_level2" v-bind:key="item.key" align-self="center" >
            <v-icon size="80" @click="openClick(item)" v-if="!item.file">
            {{'mdi-folder amber--text'}}
            </v-icon>
            <div v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'"
            v-show="progresscircular" 
            class="content-center">
            <v-progress-circular
              indeterminate
              :size="30"
              color="blue-grey lighten-4"
            ></v-progress-circular>
            </div>
            <v-img v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'"
              contain
              @loadstart="onImgloadStart"
              @load="onImgload"
              class="row-pointer"
              max-height="100"
              max-width="100"
              :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+item.key+'?download'" 
              @click="viewImageGallery(item)"
            ></v-img>
            <v-icon size="80" @click="openClick(item)"  v-else>
            {{ file_mdi[item.file] }}
            </v-icon>
            <p class="ml-3"><small>{{ item.name }}</small></p>
        </v-col>
      </v-row>
      </div>

      <!-- Image dialog box -->
        <template>
          <v-dialog v-model="image_dialog" width="unset">
            <v-img contain max-height="90vh" max-width="130vh" :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'"></v-img>
          </v-dialog>
        </template>

      <!-- Show File -->
        <div v-show="show_file">
        <v-row>
        <v-col md="6" class="text-center">
          <div  v-if="fileFile === 'jpg' | fileFile === 'JPG' | fileFile === 'png' | fileFile === 'PNG' | fileFile === 'bmp' | fileFile === 'BMP' | fileFile === 'gif' | fileFile === 'GIF' | fileFile === 'ico' | fileFile === 'ICO' | fileFile === 'jpeg' | fileFile === 'JPEG' | fileFile === 'svg' | fileFile === 'SVG' | fileFile === 'tif' | fileFile === 'TIF' | fileFile === 'tiff' | fileFile === 'TIFF' | fileFile === 'webp' | fileFile === 'WEBP'"
          v-show="progresscircular" 
          class="content-center" >
            <v-progress-circular
              indeterminate
              :size="50"
              color="blue-grey lighten-4"
            ></v-progress-circular>
          </div>
          <a  style="text-decoration: none" :href="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'" target="_blank">
          <v-img v-if="fileFile === 'jpg' | fileFile === 'JPG' | fileFile === 'png' | fileFile === 'PNG' | fileFile === 'bmp' | fileFile === 'BMP' | fileFile === 'gif' | fileFile === 'GIF' | fileFile === 'ico' | fileFile === 'ICO' | fileFile === 'jpeg' | fileFile === 'JPEG' | fileFile === 'svg' | fileFile === 'SVG' | fileFile === 'tif' | fileFile === 'TIF' | fileFile === 'tiff' | fileFile === 'TIFF' | fileFile === 'webp' | fileFile === 'WEBP'"
            contain
            @loadstart="onImgloadStart"
            @load="onImgload"
            max-height="800"
            max-width="800"
            :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'"
          ></v-img>
          <v-icon v-else size="200" :href="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'" target="_blank">
              {{ file_mdi[fileFile] }}
          </v-icon>
          </a>
          
        </v-col>
        <v-col md="6">
          <p><b>{{fileName}}</b></p>
          <p>
          <small>File: <b>{{fileFile}}</b></small><br>
          <small>Size: <b>{{fileSizeConvert}}</b></small><br>
          <small>Created: <b>{{fileCreated}}</b></small>
          </p>
          <v-btn icon :href="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'" target="_blank">
            <v-icon small>mdi-download</v-icon>
          </v-btn>
          <p><small><br>Real-time locations of the file’s pieces on decentralised storage:</small></p>
          <v-img
            contain
            max-height="400"
            max-width="400"
            :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?map=1&width=400'"
          ></v-img>
        </v-col>
        </v-row>
        </div>

  </v-container> 
    
</v-main>

  <v-footer padless>
        <v-col class="text-center" cols="12">
          2022 — <strong>MyTrustedCloud.io</strong> [Alpha]
        </v-col>
    </v-footer>
    
</v-app>
  
</template>

<script>
  import axios from "axios";
  import file_mdi from "@/data/file_mdi";


  export default {
  data() {
    return {
      file_mdi: file_mdi,
      accessToken:'',
      preFix:'',
      accessGrant:'',
      displayAccessKey:'',
      fileName:'',
      fileKey:'',
      fileKeyCustom:'',
      fileSize:'',
      fileType:'',
      fileFile:'',
      fileSizeConvert:'',
      fileCreated:'',
      mtcName:'',
      breadcrumb_length:'',
      state: true,
      show_level2_list: true,
      show_level2_grid: false,
      show_level2_explore: true,
      show_file: false,
      breadcrumb_object:{},
      breadcrump:[],
      breadcrumbs_show: true,
      collection:[],
      collection_level2:[],
      progresscircular: false,
      duration:this.$route.query.duration,
      image_dialog: false,
    }
  },
  
  created: function () {
    window.onpopstate = function () { location.reload() };
    this.getToken_share();
    this.initBreadcrumbs();
    },
    
  methods: {
  
  onImgloadStart(){
      this.progresscircular = true;
    },

  onImgload(){
    this.progresscircular = false;
  },

  getHeaders(){
      if (this.$vuetify.breakpoint.smAndDown == 0) {
        return [
        { text: 'Name', value: 'name',  width: '30%' },
        { text: 'File', value: 'file',  width: '10%' },
        { text: 'Size', value: 'size',  width: '10%' },
        { text: 'Created', value: 'created', width: '20%' },
        { text: 'Actions', value: 'actions',  sortable: false,  width: '30%' }
        ]
      } else {
        return [
        { text: 'Name', value: 'name',  width: '40%' },
        { text: 'Actions', value: 'actions',  sortable: false,  width: '60%' }
        ]
      }
    },

  viewList () {
    this.state = true,   
    this.show_level2_explore = true,
    this.show_level2_list = true,
    this.show_level2_grid = false,
    this.show_file = false;
  },

  viewGrid() {
    this.state = false,   
    this.show_level2_explore = true,
    this.show_level2_list = false,
    this.show_level2_grid = true,
    this.show_file = false;
  },

  viewImageGallery(item) {
        this.image_dialog = true;   
        this.fileKey = item.key;        
      },
  
  //File conversion octets
  FileConvertSize(aSize){
  aSize = Math.abs(parseInt(aSize, 10));
  var def = [[1, 'octets'], [1000, 'kb'], [1000*1000, 'Mb'], [1000*1000*1000, 'Gb'], [1000*1000*1000*1000, 'Tb']];
  for(var i=0; i<def.length; i++){
    if(aSize<def[i][0]) return (aSize/def[i-1][0]).toFixed(2)+' '+def[i-1][1];
  }
  },

  initBreadcrumbs(){
    this.breadcrump= [];
    var breadcrump_object = {};
    var myArray = this.preFix.split("/");
    var slash = this.$route.query.prefix.slice(-1)
    if (slash == "/"){
      this.breadcrumb_length = myArray.length;
    }
    else {
      this.breadcrumb_length = myArray.length+1;
    }
    this.breadcrumbs_show = true;
    breadcrump_object.name = this.$route.query.sharename;
    breadcrump_object.type = 'path';
    breadcrump_object.key = this.$route.query.prefix;
    this.breadcrump.push({...breadcrump_object});
  },

  // Visualisation de l'objet
      openClick (item) {
      var myArray = item.key.split("/");
      var length = myArray.length;
      
      this.breadcrump= [];
      var breadcrump_object = {};
      breadcrump_object.name = this.$route.query.sharename;
      breadcrump_object.type = 'path';
      breadcrump_object.key = this.$route.query.prefix;
      this.breadcrump.push({...breadcrump_object});
      for (let i = this.breadcrumb_length+1; i < length-this.breadcrumb_length; i++) {
        breadcrump_object.name = myArray[i];
        breadcrump_object.type = 'path';
        breadcrump_object.key = myArray[i]+"/";
        if (i > 0) {
          for (let j = 0; j < i; j++) {
            breadcrump_object.key = myArray[i-j-1]+"/"+breadcrump_object.key;
            }
        }
        this.breadcrump.push({...breadcrump_object});
       }
        this.fileName = item.name;
        this.fileSize = item.size;
        this.fileCreated = item.created;
        this.fileSizeConvert = this.FileConvertSize(this.fileSize);
        this.fileKey = item.key;
        this.fileType = item.type;
        this.fileFile = item.file;
        if(item.type == 'path'){
          this.getObjects_share(this.fileKey);
          this.show_level2_explore = true,
          this.show_level2_list = this.state,
          this.show_level2_grid = !this.state,
          this.show_file = false;
        }
        else{
          this.show_level2_explore = false,
          this.show_level2_list = false,
          this.show_level2_grid = false,
          this.show_file = true;
        }
        },

  // Get Buckets
  getToken_share: function () {
        axios.post(process.env.VUE_APP_BACKENDAPI+'/api/tokens')
        .then(res => {
            this.accessToken= JSON.parse(res.data).access_token;
            this.accessGrant= this.$route.query.access;
            this.mtcName = this.$route.query.userid;
            //console.log(this.accessToken);
            this.getObjects_share(this.$route.query.prefix);
            this.displayAccess(this.$route.query.prefix);
        })
        .catch(err => {
            // handle error
            console.log(err);
        })
    },
  
  // Get Objects in a Bucket with prefix parameter
  getObjects_share: function (preFix) {
    axios.get(process.env.VUE_APP_BACKENDAPI+'/api/objects?prefix='+preFix+'&access='+this.accessGrant, {headers: {'Authorization':'Bearer '+ this.accessToken}})
        .then(res => {
                this.objects= res.data;
                this.collection = [... new Set(this.objects.map(JSON.stringify))].map(JSON.parse);
                var slash = preFix.slice(-1)
                if (slash == "/"){
                  this.collection_level2 = this.collection.filter(function(object) {return object.parent_key == preFix });
                  this.collection_level2 = this.collection_level2.sort(this.tri);
                  this.collection_level2= this.collection_level2.sort(function(a, b) {return a.file.localeCompare(b.file);});
                }
                else {
                  this.collection_level2 = this.collection.filter(function(object) {return object.key == preFix });
                }
            })
        .catch(err => {
            // handle error
            console.log(err);
        })
    },  
    
  // get 30 min access key to display image
  displayAccess: function (preFix) {

      this.displayAccessKey = "";

      //Initialize the form data
      let formData = new FormData();

      formData.append('prefix', preFix)
      formData.append('duration', "30")

      axios.post( process.env.VUE_APP_BACKENDAPI+'/api/shares?userid='+this.mtcName, formData, {headers: {'Content-Type': 'multipart/form-data','Authorization':'Bearer '+ this.accessToken} } )
          .then(res => {
            this.displayAccessKey = res.data.access_key_id;
          })
          .catch(err => {
            // handle error
            console.log(err);
          });
      return new Promise((resolve) => {
        resolve();
      });
      },

  },
 
}
</script>

<style scoped>

.transparent {
    background-color: transparent;
  }

.content-center {
    text-align:center;
  }

.image-icon {
    float:left;
    cursor: pointer;
  }

.row-pointer {
  cursor: pointer;
  }

.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {  
cursor: pointer;
}
</style>
