<template>
  <v-app>
    <!-- App bar -->
    <v-app-bar app elevation="0">
      <v-app-bar-nav-icon v-if="$auth.isAuthenticated" class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="$auth.isAuthenticated">MyTrustedCloud.io</v-toolbar-title>
      <v-spacer />
      <v-toolbar-title v-if="$auth.isAuthenticated" class="menu indigo--text hidden-sm-and-down">App</v-toolbar-title>
      <v-toolbar-title v-if="$auth.isAuthenticated" class="grey--text hidden-sm-and-down">&nbsp;|&nbsp;</v-toolbar-title>
      <v-toolbar-title v-if="$auth.isAuthenticated" @click="$router.push('/about')" class="menu hidden-sm-and-down">About</v-toolbar-title>
      <v-toolbar-title v-if="!$auth.isAuthenticated" class="menu indigo--text">App</v-toolbar-title>
      <v-toolbar-title v-if="!$auth.isAuthenticated" class="grey--text">&nbsp;|&nbsp;</v-toolbar-title>
      <v-toolbar-title v-if="!$auth.isAuthenticated" @click="$router.push('/about')" class="menu">About</v-toolbar-title>
      <v-spacer />
      <!-- Check that the SDK client is not currently loading before accessing is methods -->
      <div v-if="!$auth.loading" right class="hidden-sm-and-down">
      <!-- show user name and account button when authenticated -->
      <small class="font-weight-medium mr-1" v-if="$auth.isAuthenticated" style="font-size:0.9em">{{ $auth.user.name }}</small>
      <v-menu offset-y v-if="$auth.isAuthenticated">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab plain color="indigo" dark v-bind="attrs" v-on="on">
            <v-icon dark>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item to="/profile">
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      </div>
    </v-app-bar>

    <!-- Navigation Drawer - Tree -->
    <v-navigation-drawer app v-model="drawer" class="black">              
        <v-treeview
        dense
        dark
        class ="mt-3"
        :open.sync="open"
        :active.sync="active"
        v-model="tree"
        :items="collection_tree"
        activatable
        item-key="name"
        hoverable
        return-object
        active-class="primary--text"
        @update:active="openClickTreeViewActive"
        >
        <template slot="label" slot-scope="{ item, open }">
          <v-icon  class="white--text" v-if="!item.file">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <v-icon class="white--text" v-else>
            {{ files[item.file] }}
          </v-icon>
          <a style="color:inherit;" @click="openClick(item)" class="white--text ml-1">{{ item.name }}</a>
        </template>
        </v-treeview>
    </v-navigation-drawer>

    <!-- Main -->
    <v-main  class="grey lighten-4 pt-0 pl-0">
      <!-- show login when not authenticated -->
      <div v-if="!$auth.isAuthenticated" style="text-align:center">
      <h2>MyTrustedCloud.io</h2>
      <p>Experience web3 decentralized cloud storage</p>  
      <v-btn color="indigo" class="white--text" @click="login">Log in</v-btn> 
      </div>
     
     <!-- Main content container --> 
      <v-container v-if="$auth.isAuthenticated"> 
      
      <!-- Menu for mobile-->
      <div class="ml-5 hidden-md-and-up">
      <p @click="$router.push('/')" class="menu d-inline-flex indigo--text">App</p>
      <p class="grey--text d-inline-flex">&nbsp;|&nbsp;</p>
      <p @click="$router.push('/about')" class="menu d-inline-flex">About</p>
      </div>
      <!-- Check that the SDK client is not currently loading before accessing is methods -->
      <div v-if="!$auth.loading" right class="ml-5 hidden-md-and-up">
      <!-- show user name and account button when authenticated -->
      <small class="font-weight-medium mr-1" v-if="$auth.isAuthenticated" style="font-size:0.9em">{{ $auth.user.name }}</small>
      <v-menu offset-y v-if="$auth.isAuthenticated">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab plain color="indigo" dark v-bind="attrs" v-on="on">
            <v-icon dark>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item to="/profile">
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-title>Log out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      </div>

        <!-- Storage used capacity -->   
        <v-col md="6">
          <v-progress-circular
          class="ml-1"
          :value="storage_percent"
          color="indigo darken-2"
          >
          </v-progress-circular> 
          <p v-if="storage != ''" class="ml-2 d-inline-flex indigo--text"><small> {{storage_percent}} % storage capacity - {{ storage_convert }}/{{ maxStorage_convert }}</small></p>
          <p v-if="storage == ''" class="ml-2 d-inline-flex grey--text"><small><i>Waiting for data ...</i></small></p>

        </v-col>   
        <!-- Upload button and share button -->
        <v-btn  class="mb-2 ml-2" icon x-large color="indigo" dark @click="handleUploadDialog()">
                  <v-icon>mdi-cloud-upload</v-icon>
        </v-btn>
        <v-btn  class="mb-2 ml-2" icon x-large color="indigo" dark @click="handleShareDialog()">
                  <v-icon>mdi-share-variant</v-icon>
        </v-btn>
        
        <!-- Upload dialog box -->
        <v-dialog v-model="dialog_upload" max-width="600px">
            <v-card>
              <v-form ref="uploadForm">
                  <v-card-title>
                      <span class="headline">Upload file(s)</span>
                  </v-card-title>
                  <v-card-text>
                      <v-container>
                          <v-row>
                              <v-col cols="12">
                                  <v-file-input small-chips show-size multiple label="Select file(s)" :rules="fileRules" v-model="files" @change="handleFileUpload()"></v-file-input>
                              </v-col>
                              <v-col cols="12">
                                <v-combobox :items="collection_path" item-text="key_custom" :rules="inputRules" label="Select or create a new path" v-model="path" ref="pathCombobox" :return-object="false" required>
                                </v-combobox>
                                <p>Example: mytrustedcloud/<strong>mypath/</strong></p>
                              </v-col>
                          </v-row>
                      </v-container>
                  </v-card-text>
                  
                  <v-card-actions>
                    <v-col class="text-center">
                    <v-btn :loading="isLoading" color="indigo" class="ma-2 white--text" @click.native="postFiles()">
                        Upload
                        <v-icon right dark>
                          mdi-cloud-upload
                        </v-icon>
                      </v-btn>
                  </v-col>   
                  </v-card-actions>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click.native="dialog_upload = false">Close</v-btn>
                  </v-card-actions>
              </v-form>
            </v-card>
        </v-dialog>
        <v-divider class="white"></v-divider>
                    
        <!-- Autocomplete path -->
         
        <v-autocomplete class="ml-3 mt-1" 
          v-model="fileKeyCustom"
          :items="collection"
          item-text="key_custom"
          label="Search for a path or a file"
          dense
          outlined
          return-object
          @change="openClick"
        ></v-autocomplete>
        
        <!-- Breadcrumbs -->
        <v-row v-if="breadcrumbs_show" no-gutters>
            <v-icon size="30" class="ml-5" color="indigo">
                {{'mdi-folder-open amber--text'}}        
            </v-icon>
            <v-breadcrumbs class="pl-3" :items="breadcrump" divider=">">
                <template v-slot:item="{ item }">
                  <v-breadcrumbs-item
                  class="row-pointer indigo--text"
                  @click.native="openClick(item)"
                  >
                    {{ item.name }}
                  </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-row>

        
        
        <!-- Show File -->
        <div v-show="show_file">
        <v-row>
        <v-col md="6" class="text-center">
          <div  v-if="fileFile === 'jpg' | fileFile === 'JPG' | fileFile === 'png' | fileFile === 'PNG' | fileFile === 'bmp' | fileFile === 'BMP' | fileFile === 'gif' | fileFile === 'GIF' | fileFile === 'ico' | fileFile === 'ICO' | fileFile === 'jpeg' | fileFile === 'JPEG' | fileFile === 'svg' | fileFile === 'SVG' | fileFile === 'tif' | fileFile === 'TIF' | fileFile === 'tiff' | fileFile === 'TIFF' | fileFile === 'webp' | fileFile === 'WEBP'"
          v-show="progresscircular" 
          class="content-center" >
            <v-progress-circular
              indeterminate
              :size="50"
              color="blue-grey lighten-4"
            ></v-progress-circular>
          </div>
          <a  style="text-decoration: none" :href="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'" target="_blank">
          <v-img  v-if="fileFile === 'jpg' | fileFile === 'JPG' | fileFile === 'png' | fileFile === 'PNG' | fileFile === 'bmp' | fileFile === 'BMP' | fileFile === 'gif' | fileFile === 'GIF' | fileFile === 'ico' | fileFile === 'ICO' | fileFile === 'jpeg' | fileFile === 'JPEG' | fileFile === 'svg' | fileFile === 'SVG' | fileFile === 'tif' | fileFile === 'TIF' | fileFile === 'tiff' | fileFile === 'TIFF' | fileFile === 'webp' | fileFile === 'WEBP'"
            contain
            @loadstart="onImgloadStart"
            @load="onImgload"
            max-height="800"
            max-width="800"
            :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'"
          ></v-img>
          <v-icon v-else size="200" :href="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'" target="_blank">
              {{ file_mdi[fileFile] }}
          </v-icon>
          </a>
          
        </v-col>
        <v-col md="6">
          <p><b>{{fileName}}</b></p>
          <p><small>Path: <b>{{fileKeyCustom}}</b></small><br>
          <small>File: <b>{{fileFile}}</b></small><br>
          <small>Size: <b>{{fileSizeConvert}}</b></small><br>
          <small>Created: <b>{{fileCreated}}</b></small>
          </p>
          <v-btn icon @click="deleteObjects(fileKey, fileKeyCustom, fileParentKey, fileType)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
          <v-btn icon :href="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'" target="_blank">
            <v-icon small>mdi-download</v-icon>
          </v-btn>
          <v-btn icon @click="handleShareObject(fileKey, fileKeyCustom)">
            <v-icon small>mdi-share-variant</v-icon>
          </v-btn>
          <v-btn icon @click="handleMoveObject(fileKeyCustom, fileKey, fileType, fileName)">
            <v-icon small>mdi-folder-move</v-icon>
          </v-btn>
          <p><small><br>Real-time locations of your file’s pieces on Storj decentralised storage:</small></p>
          <v-img
            contain
            max-height="400"
            max-width="400"
            :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?map=1&width=400'"
          ></v-img>
        </v-col>
        </v-row>
        </div>
        
        <!-- Explore level 2 - choose exploration type: list or grid -->
        <v-col md="12" class="text-left" v-show="show_level2_explore">
          <v-icon size="36" color="indigo" right @click="viewList()">
          mdi-view-list
          </v-icon>
          <v-icon size="40" color="indigo" right @click="viewGrid()">
          mdi-view-module
          </v-icon>
        </v-col>
              
        <!-- Show Level 2 list -->
        <div v-show="show_level2_list">
        <v-col md="12">
        <v-data-table
          :headers="getHeaders()"
          :items="collection_level2"
          class="transparent row-pointer"
          mobile-breakpoint="0"
          @click:row="openClick"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-icon x-large v-if="!item.file" @click="openClick(item)">
            {{'mdi-folder amber--text'}}
            </v-icon>
            <div v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'" 
            v-show="progresscircular" 
            class="content-center, image-icon">
            <v-progress-circular
              indeterminate
              :size="20"
              color="blue-grey lighten-4"
            ></v-progress-circular>
            </div>
            <v-img v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'"
            class="image-icon"
            @loadstart="onImgloadStart"
            @load="onImgload"
            contain
            max-height="40"
            max-width="40"
            @click="openClick(item)"
            :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+item.key+'?download'"
            ></v-img>
            <v-icon x-large v-else @click="openClick(item)">
            {{ file_mdi[item.file]}}
            </v-icon>
            <a v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'"
            >&nbsp;</a>
            <a style="color:#000000" @click="openClick(item)">{{ item.name }}</a>
          </template>
          <template v-slot:[`item.file`]="{ item }">
              <td class="hidden-sm-and-down text-align-center">{{ item.file }}</td>
          </template> 
          <template v-slot:[`item.size`]="{ item }">
               <td v-if="item.file">{{ FileConvertSize(item.size) }}</td>
          </template> 
          <template v-slot:[`item.created`]="{ item }">
              <td class="hidden-sm-and-down text-align-center">{{ item.created }}</td>
          </template> 
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="deleteObjects(item.key, item.key_custom, item.parent_key, item.type)">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon v-if="item.type === 'file'" :href="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+item.key+'?download'" target="_blank">
              <v-icon small>mdi-download</v-icon>
            </v-btn>
            <v-btn icon disabled v-else>
              <v-icon small>mdi-download</v-icon>
            </v-btn>
            <v-btn icon @click="handleShareObject(item.key, item.key_custom)">
              <v-icon small>mdi-share-variant</v-icon>
            </v-btn>
            <v-btn icon @click="handleMoveObject(item.key_custom, item.key, item.type, item.name)">
              <v-icon small>mdi-folder-move</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        </v-col>
        </div>

        <!-- Show Level 2 grid -->
        <div v-show="show_level2_grid" class="ml-5">
          <v-row class="mt-4" >
          <v-col md="2" v-for="item in collection_level2" v-bind:key="item.key" align-self="center" >
              <v-icon size="80" @click="openClick(item)" v-if="!item.file">
              {{'mdi-folder amber--text'}}
              </v-icon>
              <div v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'"
              v-show="progresscircular" 
              class="content-center">
              <v-progress-circular
                indeterminate
                :size="30"
                color="blue-grey lighten-4"
              ></v-progress-circular>
              </div>
              <v-img v-if="item.file === 'jpg' | item.file === 'JPG' | item.file === 'png' | item.file === 'PNG' | item.file === 'bmp' | item.file === 'BMP' | item.file === 'gif' | item.file === 'GIF' | item.file === 'ico' | item.file === 'ICO' | item.file === 'jpeg' | item.file === 'JPEG' | item.file === 'svg' | item.file === 'SVG' | item.file === 'tif' | item.file === 'TIF' | item.file === 'tiff' | item.file === 'TIFF' | item.file === 'webp' | item.file === 'WEBP'"
                contain
                @loadstart="onImgloadStart"
                @load="onImgload"
                class="row-pointer"
                max-height="100"
                max-width="100"
                :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+item.key+'?download'" 
                @click="viewImageGallery(item)"
              ></v-img>
              <v-icon size="80" @click="openClick(item)"  v-else>
              {{ file_mdi[item.file] }}
              </v-icon>
              
              <p class="ml-3"><small>{{ item.name }}</small></p>
          </v-col>
        </v-row>
        </div>
    
        <!-- Share dialog box -->
        <template>
        <v-dialog v-model="dialog_share" max-width="600px">
            <v-card>
              <v-form ref="shareForm">
              <v-card-title>
                  <span class="headline">Share a path or a file</span>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col cols="12">
                              <v-text-field readonly v-model="sharedFile"></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                          v-model="sharedDuration"
                          :items="shared_duration"
                          item-text="duration_text"
                          item-value="duration"
                          label="Select share duration"
                          dense
                          outlined
                          return-object
                          @change="updateDuration"
                        ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                          <v-text-field
                            v-model="share_name"
                            :rules="inputRules"
                            label="Give a name to your share"
                          >
                          </v-text-field>
                      </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-col class="text-center">
                  <v-btn :loading="isLoading" color="indigo" class="ma-2 white--text" @click.native="shareObjects()">
                    Share
                    <v-icon dark>
                      mdi-share-variant
                    </v-icon>
                  </v-btn> 
                </v-col>   
              </v-card-actions>
              <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="10">
                            <v-text-field class="share-url" color="indigo" readonly v-model="sharedAccessKey"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-btn icon color="indigo" @click="copyTextToClipboard()">
                            <v-icon small>mdi-content-copy</v-icon>
                          </v-btn>
                      </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.native="dialog_share = false">Close</v-btn>
          </v-card-actions>
          </v-form>
          </v-card>
      </v-dialog>
        </template>


        <!-- Move dialog box -->
        <template>
        <v-dialog v-model="dialog_move" max-width="600px">
            <v-card>
               <v-form ref="moveForm">
              <v-card-title>
                  <span class="headline">Move path or file</span>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col cols="12">
                              <v-text-field readonly v-model="moveFile"></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete :items="collection_path" :rules="inputRules" item-text="key_custom" item-value="key" label="Select parent path" v-model="move_path" ref="pathCombobox" :return-object="false" required>
                          </v-autocomplete>
                        </v-col>
                    </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-col class="text-center">
                  <v-btn :loading="isLoading" color="indigo" class="ma-2 white--text" @click.native="moveObjects()">
                    Move
                    <v-icon dark>
                      mdi-share-variant
                    </v-icon>
                  </v-btn> 
                </v-col>   
                </v-card-actions>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click.native="dialog_move = false">Close</v-btn>
              </v-card-actions>
              </v-form>
          </v-card>
      </v-dialog>
        </template>


        <!-- Alert info - you can not delete welcome.txt -->
        <template>
          <v-dialog v-model="delete_alert" max-width="700px">
            <v-card>
              <v-card-title>
                  <span class="headline">Warning</span>
              </v-card-title>
              <v-card-text>
                <v-alert type="warning">
                You can not delete nor move your root file: <strong>mytrustedcloud/welcome.txt</strong>
              </v-alert>
              </v-card-text> 
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.native="delete_alert = false">Close</v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>
        </template>

        <!-- Alert info - You need to select a path or a file for qharing -->
        <template>
          <v-dialog v-model="share_alert" max-width="700px">
            <v-card>
              <v-card-title>
                  <span class="headline">Warning</span>
              </v-card-title>
              <v-card-text>
                <v-alert type="warning">
                You need to select a path or a file !
              </v-alert>
              </v-card-text> 
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.native="share_alert = false">Close</v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>
        </template>

        <!-- Alert info - bad path format on Postfile -->
        <template>
          <v-dialog v-model="path_alert" max-width="700px">
            <v-card>
              <v-card-title>
                  <span class="headline">Warning</span>
              </v-card-title>
              <v-card-text>
                <v-alert type="warning">
                <strong>Path is not in the good format !</strong><br>
                - Path must start with: <strong>mytrustedcloud/</strong><br>
                - Path must end with: <strong>/</strong><br>
                - No: <strong>//</strong><br>
                Example of valid path: <strong>mytrustedcloud/path1/path2/</strong>
              </v-alert>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.native="path_alert = false">Close</v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>
        </template>

        <!-- Alert info - No File to upload ! -->
        <template>
          <v-dialog v-model="field_alert" max-width="700px">
            <v-card>
              <v-card-title>
                  <span class="headline">Warning</span>
              </v-card-title>
              <v-card-text>
                <v-alert type="warning">
                No File to upload !
              </v-alert>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click.native="field_alert = false">Close</v-btn>
            </v-card-actions>
          </v-card>
          </v-dialog>
        </template>

         <!-- Image dialog box -->
        <template>
          <v-dialog v-model="image_dialog" width="unset">
            <v-img contain max-height="90vh" max-width="130vh" :src="'https://link.eu1.storjshare.io/s/'+displayAccessKey+'/mytrustedcloud/'+fileKey+'?download'"></v-img>
          </v-dialog>
        </template>

      </v-container>  
    </v-main>  

    <v-footer padless>
        <v-col class="text-center" cols="12">
          2022 — <strong>MyTrustedCloud.io</strong> [Alpha]
        </v-col>
    </v-footer>

  </v-app>
</template>


<script>
  import axios from "axios";
  import { getInstance } from "@/auth/index";
  import file_mdi from "@/data/file_mdi";
  

  export default {
    data() {
      return {
          file_mdi: file_mdi,
          mini:true,
          buckets: [],
          objects: [],
          collection:[],
          collection_path:[],
          collection_file:[],
          collection_tree:[],
          objects2: [],
          collection2:[],
          collection_level2:[],
          breadcrumb_object:{},
          breadcrump:[],
          tree: [],
          initiallyOpen: ['public'],
          active:[],
          open:[],

          files:null,
          path:'',
          pathpost:'',
          move_path:'',
          fileName:'',
          fileKey:'',
          fileParentKey:'',
          fileKeyCustom:'',
          fileSize:'',
          fileType:'',
          fileFile:'',
          fileSizeConvert:'',
          fileCreated:'',
          sharedFile:'',
          sharedFile2:'',
          moveFile:'',
          moveFile2:'',
          sharedAccessKey:'',
          displayAccessKey:'',
          sharedDuration:'',
          storage: 0,
          storage_convert: 0,
          storage_percent: 0,
          subscription:'',
          maxStorage: '',
          maxStorage_convert:'',
          maxUpload: '',
          mtcName:'',
          breadcrumbs_show: false,
          progresscircular: false,
          show_file: false,
          show_level2_list: false,
          show_level2_grid: false,
          show_level2_explore: false,
          drawer: false,
          dialog_upload: false,
          dialog_share: false,
          dialog_move: false,
          delete_alert: false,
          path_alert:false,
          field_alert:false,
          share_alert:false,
          state: true,
          isLoading: false,
          access_grant_success:'',
          accessToken:'',
          sharedDurationText:'1 hour',
          share_name:'',
          image_dialog: false,
          shareUrl:'',

          fileRulesText: {
            trial: {
              upload_quota: 'File(s) total size should be less than 50 MB!',
              storage_quota: 'You will exceed your maximum storage capacity of 2 GB!'
            },
            free: {
              upload_quota: 'File(s) total size should be less than 2 GB!',
              storage_quota: 'You will exceed your maximum storage capacity of 20 GB!'
            },
            premium: {
              upload_quota: 'File(s) total size should be less than 10 GB!',
              storage_quota: 'You will exceed your maximum storage capacity of 1 TB!'
            }
          },         

          inputRules: [
          v => !!v || 'Field is required',
          ],

          fileRules: [
          value => !value || value.reduce((size, file) => size + file.size, 0) < this.maxUpload || this.fileRulesText[this.subscription].upload_quota,
          value => !value || value.reduce((size, file) => size + file.size + this.storage, 0) < this.maxStorage || this.fileRulesText[this.subscription].storage_quota,
          ],

          shared_duration:[
          {
            duration_text:'1 hour',
            duration:'60'
          },
          {
            duration_text:'4 hours',
            duration:'240'
          },
          {
            duration_text:'12 hours',
            duration:'720'
          },
          {
            duration_text:'24 hours',
            duration:'1440'
          },
          {
            duration_text:'48 hours',
            duration:'2880'
          },
          {
            duration_text:'1 week',
            duration:'10080'
          },
          {
            duration_text:'1 month',
            duration:'40320'
          }
        ]
      }
    },

  

  created: function () {
    window.onpopstate = function () { location.reload() };
    this.init(this.loadToken);
    },
    
    
    methods: {

      // Log the user in
      login() {
        this.$auth.loginWithRedirect()
      },


      // Log the user out
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin
        });
      },
      
      init(fn) {
        // have to do this nonsense to make sure auth0Client is ready
        var instance = getInstance();
        instance.$watch("loading", loading => {
            if (loading === false) {
                fn(instance);
            }
        });
      },
      
      async loadToken(instance) {
          await instance.getTokenSilently().then((authToken) => {
            // do authorized API calls with auth0 authToken here 
            // or load authToken in state store for other components to use
            this.accessToken = authToken;
            this.initSession();
          });
      },

      openClickTreeViewActive () {
      this.openClick(this.active[0]);
      },

      getHeaders(){
        if (this.$vuetify.breakpoint.smAndDown == 0) {
          return [
          { text: 'Name', value: 'name',  width: '30%' },
          { text: 'File', value: 'file',  width: '10%' },
          { text: 'Size', value: 'size',  width: '10%' },
          { text: 'Created', value: 'created', width: '20%' },
          { text: 'Actions', value: 'actions',  sortable: false,  width: '30%' }
          ]
        } else {
          return [
          { text: 'Name', value: 'name',  width: '50%' },
          { text: 'Actions', value: 'actions',  sortable: false,  width: '50%' }
          ]
        }
      },

      // update breadcrumbs
      updateBreadcrumbs (item_key, item_key_custom) {
      this.breadcrumbs_show = true;
      var myArray = item_key.split("/");
      var myArray2 = item_key_custom.split("/");
      var length = myArray.length;
      this.breadcrump= [];
      var breadcrump_object = {};

      for (let i = 0; i < length-1; i++) {
        breadcrump_object.name = myArray2[i];
        breadcrump_object.type = 'path';
        breadcrump_object.key = myArray[i]+"/";
        breadcrump_object.key_custom = myArray2[i]+"/";
        if (i > 0) {
          for (let j = 0; j < i; j++) {
            breadcrump_object.key = myArray[i-j-1]+"/"+breadcrump_object.key;
            breadcrump_object.key_custom = myArray2[i-j-1]+"/"+breadcrump_object.key_custom;
            }
        }
        this.breadcrump.push({...breadcrump_object});
       }
      },

      
      // Visualisation de l'objet
      openClick (item) {
        this.updateBreadcrumbs(item.key, item.key_custom);
        this.fileName = item.name;
        this.fileSize = item.size;
        this.fileCreated = item.created;
        this.fileSizeConvert = this.FileConvertSize(this.fileSize);
        this.fileKey = item.key;
        this.fileType = item.type;
        this.fileFile = item.file;
        this.fileParentKey = item.parent_key;
        this.fileKeyCustom = item.key_custom;
        if(item.type == 'path'){
          this.path = item.key_custom;
          this.getObjects2(this.fileKey);
          this.show_level2_explore = true,
          this.show_level2_list = this.state,
          this.show_level2_grid = !this.state,
          this.show_file = false;
        }
        else{
          var myArray3 = item.key_custom.split("/");
          var length2 = myArray3.length;
          this.path="mytrustedcloud/"
          for (var i = 1; i < length2-1; i++ ){
          this.path = this.path + myArray3[i] + "/"
          }
          this.show_level2_explore = false,
          this.show_level2_list = false,
          this.show_level2_grid = false,
          this.show_file = true;
        }
        },

      viewList () {
          this.state = true,   
          this.show_level2_explore = true,
          this.show_level2_list = true,
          this.show_level2_grid = false,
          this.show_file = false;
      },

      viewGrid() {
        this.state = false,   
        this.show_level2_explore = true,
        this.show_level2_list = false,
        this.show_level2_grid = true,
        this.show_file = false;
      },

      viewImageGallery(item) {
        this.image_dialog = true;   
        this.fileKey = item.key;        
      },

      onImgloadStart(){
        this.progresscircular = true;
      },

      onImgload(){
        this.progresscircular = false;
      },

      // Copy text to Clipboard
      copyTextToClipboard() {
        navigator.clipboard.writeText(this.sharedAccessKey);
      },
      
      //File conversion octets
      FileConvertSize(aSize){
      if(aSize==0) return 0;
      aSize = Math.abs(parseInt(aSize, 10));
      var def = [[1, 'octets'], [1000, 'kb'], [1000*1000, 'Mb'], [1000*1000*1000, 'Gb'], [1000*1000*1000*1000, 'Tb']];
      for(var i=0; i<def.length; i++){
        if(aSize<def[i][0]) return (aSize/def[i-1][0]).toFixed(2)+' '+def[i-1][1];
      }
      },

      // Tri collection par ordre alphabetique
      tri(a,b)
      {
      return (a.key > b.key)?1:-1;
      }, 

      //transform to collection_tree
      transData(a, idStr, pidStr, chindrenStr) {   
        var r = [], hash = {}, id = idStr, pid = pidStr, children = chindrenStr, i = 0, j = 0, len = a.length;  
          for(; i < len; i++){  
              hash[a[i][id]] = a[i];  
          }  
          for(; j < len; j++){  
              var aVal = a[j], hashVP = hash[aVal[pid]];  
              if(hashVP){  
                  !hashVP[children] && (hashVP[children] = []);  
                  hashVP[children].push(aVal);  
              }else{  
                  r.push(aVal);  
              }  
          }  
          this.collection_tree = r; 
          this.collection_tree = this.collection_tree.sort(this.tri); 
      },
      
      // Calculate Storage
      calculateStorage () {
        this.maxStorage_convert = this.FileConvertSize(this.maxStorage);
        this.storage = 0;
        this.storage_percent = 0;
        var collectionLength = this.collection_file.length;
        for (let i = 0; i < collectionLength; i++) {
            this.storage = parseInt(this.collection_file[i].size) + this.storage;
        }
        this.storage_convert = this.FileConvertSize(this.storage);
        this.storage_percent = Math.round(this.storage / this.maxStorage * 100);
      },
      
      // Handles opening of the upload dialog box from main icon
      handleUploadDialog(){
        this.files = null;
        this.dialog_upload = true;
      },

      // Handles opening of the share dialog box from main icon
      handleShareDialog(){
        if (this.fileKeyCustom == ""){
            this.share_alert = true;
             this.dialog_share = false;
        } else {
          this.sharedFile = this.fileKeyCustom;
          this.sharedFile2 = this.fileKey;
          this.dialog_share = true;
          this.sharedAccessKey = "";
          this.sharedDuration = this.shared_duration[0].duration;
        }
        
      },


      // Handles a change on the file upload
      handleFileUpload(){
          this.Files = this.$refs.files;
      },

      // Handles a share on a file
     handleShareObject(object_key, object_key_custom){
      this.sharedAccessKey = "";
      this.dialog_share = true;
      this.sharedFile = object_key_custom;
      this.sharedFile2 = object_key;
      this.sharedDuration = this.shared_duration[0];
      console.log(this.sharedDuration);
      },
       // Handles a move on a file
     handleMoveObject(object_key_custom, object_key, object_type, object_name){
      if (object_key_custom === 'mytrustedcloud/welcome.txt'){
            this.delete_alert = true;
          }
      else {
      this.moveFile = object_name;
      this.moveFile2 = object_key;
      this.move_type = object_type;
      this.dialog_move = true;
      }
      },

      // Handles a share on a file
     updateDuration(item){
      this.sharedDuration = item.duration;
      this.sharedDurationText = item.duration_text;
      },


      // Get Secrets to get user access grant
       initSession: function () {
        this.mtcName = this.$auth.user.sub+"/";       
        this.subscription = this.$auth.user["https://www.mytrustedcloud.io/subscription"];
        this.maxStorage = this.$auth.user["https://www.mytrustedcloud.io/maxStorage"]; 
        this.maxUpload = this.$auth.user["https://www.mytrustedcloud.io/maxUpload"];
        var parts = this.mtcName.split("|");
        this.mtcName = parts[1];
        this.getObjects(this.mtcName);
        this.displayAccess();
      },      

      
      // Get Objects in a Bucket with prefix parameter
      getObjects: function (preFix) {
        axios.get(process.env.VUE_APP_BACKENDAPI+'/api/objects?prefix='+preFix+'&userid='+this.mtcName, {headers: {'Authorization':'Bearer '+ this.accessToken}})
            .then(res => {
                this.objects= res.data;
                this.collection = [... new Set(this.objects.map(JSON.stringify))].map(JSON.parse);
                this.collection = this.collection.sort(this.tri);
                this.collection_path = this.collection.filter(function(object) {return object.type == "path" });
                this.collection_path = this.collection_path.sort(this.tri);
                this.collection_file = this.collection.filter(function(object) {return object.type == "file" });
                this.collection_file = this.collection_file.sort(this.tri);
                this.calculateStorage ();
                this.transData(this.collection_path,'key', 'parent_key', 'children');
            })
            .catch(err => {
                // handle error
                console.log(err);
            })
          }, 
      
      // Get Objects2 in a Bucket with prefix parameter level#2
      getObjects2: function (preFix) {
        axios.get(process.env.VUE_APP_BACKENDAPI+'/api/objects?prefix='+preFix+'&userid='+this.mtcName, {headers: {'Authorization':'Bearer '+ this.accessToken}})
            .then(res => {
                this.objects2= res.data;
                this.collection2 = [... new Set(this.objects2.map(JSON.stringify))].map(JSON.parse);
                this.collection_level2 = this.collection2.filter(function(object) {return object.parent_key == preFix });
                this.collection_level2 = this.collection_level2.sort(this.tri);
                this.collection_level2= this.collection_level2.sort(function(a, b) {return a.file.localeCompare(b.file);});
            })
            .catch(err => {
                // handle error
                console.log(err);
            })
       },

        // Post File
      postFiles: function () {

        if(this.$refs.uploadForm.validate()){
            var slash1 = this.path.substr(-1);
            var parts = this.path.split("/");
            var parts0 = parts[0];
            this.pathpost = this.mtcName;
            if (this.files == null){
              this.field_alert = true
              return
            }
            for (var i = 0; i < parts.length-1; i++){
              if (parts[i] == ""){
                this.path_alert = true
                return
              }
            }
            for (var j = 1; j < parts.length-1; j++){
              this.pathpost = this.pathpost + parts[j] + "/"
            }

            if (parts0 != 'mytrustedcloud' || slash1 != '/') {
              this.path_alert = true
              return
            }
            else {

              this.isLoading = true;

              //Initialize the form data
              let formData = new FormData();

              this.$nextTick(() => {

                this.$refs.pathCombobox.focus();
                //Add the form data we need to submit

                for( var i = 0; i < this.files.length; i++ ){
                  let file = this.files[i];
                  formData.append('files[' + i + ']', file);
                  formData.append('file_path', this.pathpost)
                  formData.append('file_number',this.files.length)
                }
              
            
                //Make the request to the POST /files URL
                axios.post( process.env.VUE_APP_BACKENDAPI+'/api/files?userid='+this.mtcName, formData, {headers: {'Content-Type': 'multipart/form-data','Authorization':'Bearer '+ this.accessToken} } )
                .then(res => {
                      this.getObjects(this.mtcName); 
                      this.getObjects2(this.pathpost); 
                      this.updateBreadcrumbs(this.pathpost, this.path);
                      this.dialog_upload = false;
                      this.isLoading = false;
                      this.fileKeyCustom = this.path;
                      this.show_level2_list = true;
                      return res;
                  })
                    .catch(err => {
                      // handle error
                      console.log(err);
                    });
                });
            }     
        }
        },
      
      // Share Objects
      shareObjects: function () {
        if(this.$refs.shareForm.validate()){
        
        this.isLoading = true;
        //Initialize the form data
        let formData = new FormData();

        formData.append('prefix', this.sharedFile2)
        formData.append('duration', this.sharedDuration)

        axios.post(process.env.VUE_APP_BACKENDAPI+'/api/shares?userid='+this.mtcName, formData, {headers: {'Content-Type': 'multipart/form-data', 'Authorization':'Bearer '+ this.accessToken} } )
            .then(res => {
              this.shareUrl = process.env.VUE_APP_FRONTEND+"/share?prefix="+this.sharedFile2+"&access="+res.data.accessgrant+"&userid="+this.mtcName+"&sharename="+this.share_name+"&duration="+this.sharedDurationText;
              axios.post('https://api.tinyurl.com/create?api_token=oH2fG2ddFYRLKESxjZy0f09Rm6hovzXU2jhAD7f29VkUKCCtE8mC2rjzVwKU', {url:this.shareUrl, domain:'tiny.one'}, {headers: {'Content-Type': 'application/json', 'accept':'application/json'}})
                  .then(res => {
                    this.sharedAccessKey = res.data.data.tiny_url;
                    console.log(this.sharedAccessKey);
                    return res;
                  })
                  .catch(error => {
                    // handle error
                    console.log(error);
                  });
              //this.sharedAccessKey = process.env.VUE_APP_FRONTEND+"/share?prefix="+this.sharedFile2+"&access="+res.data.accessgrant+"&userid="+this.mtcName+"&sharename="+this.share_name+"&duration="+this.sharedDurationText;
              this.isLoading = false;
           })
            .catch(err => {
              // handle error
              console.log(err);
            });
        }
        },
      
       // Delete Objects in a Bucket with prefix parameter
        deleteObjects: function (object_key, object_key_custom, object_parent_key, object_type) {
          if (object_key_custom === 'mytrustedcloud/welcome.txt'){
            this.delete_alert = true;
          }
          else {
          axios.delete(process.env.VUE_APP_BACKENDAPI+'/api/objects?prefix='+object_key+'&userid='+this.mtcName, {headers: {'Content-Type': 'multipart/form-data', 'Authorization':'Bearer '+ this.accessToken} })
              .then(res => {
                this.getObjects(this.mtcName);
                if(object_type == 'path'){
                this.getObjects2(object_parent_key);
                } else {
                this.getObjects2(object_parent_key);
                var myArray = object_parent_key.split("/");
                var length = myArray.length;
                var object_parent_key_custom = "mytrustedcloud/"
                for (var i = 1; i < length-1; i++ ){
                object_parent_key_custom = object_parent_key_custom + myArray[i] + "/"
                }
                this.fileKeyCustom = object_parent_key_custom;
                this.state = true;  
                this.show_level2_explore = true;
                this.show_level2_list = true;
                this.show_level2_grid = false;
                this.show_file = false;
                }
                return res
              })
              .catch(err => {
                // handle error
                console.log(err);
              });
            }
          },
          

        // Move Objects
      moveObjects: function () {
        if(this.$refs.moveForm.validate()){
        this.isLoading = true;
        //Initialize the form data
        let formData = new FormData();
        formData.append('type', this.move_type)
        formData.append('name', this.moveFile)
        formData.append('original_key', this.moveFile2)
        formData.append('destination_key', this.move_path)
        axios.post( process.env.VUE_APP_BACKENDAPI+'/api/moves?userid='+this.mtcName, formData, {headers: {'Content-Type': 'multipart/form-data', 'Authorization':'Bearer '+ this.accessToken} } )
            .then(res => {
              this.getObjects(this.mtcName);
              this.getObjects2(this.move_path); 
              var myArray = this.move_path.split("/");
              var length = myArray.length;
              this.fileKeyCustom = "mytrustedcloud/";
              for (var i=1; i < length-1; i++) {
              this.fileKeyCustom = this.fileKeyCustom + myArray[i] + "/";
              }
              this.updateBreadcrumbs(this.move_path, this.fileKeyCustom);
              this.isLoading = false;
              this.show_level2_list = true;
              this.dialog_move = false;
              return res;
            })
            .catch(err => {
              // handle error
              console.log(err);
            });
        }
        },


      // get 30 min access key to display image
        displayAccess: function () {

        this.displayAccessKey = "";

        //Initialize the form data
        let formData = new FormData();

        formData.append('prefix', this.mtcName)
        formData.append('duration', "30")

        axios.post( process.env.VUE_APP_BACKENDAPI+'/api/shares?userid='+this.mtcName, formData, {headers: {'Content-Type': 'multipart/form-data','Authorization':'Bearer '+ this.accessToken} } )
            .then(res => {
              this.displayAccessKey = res.data.access_key_id;
            })
            .catch(err => {
              // handle error
              console.log(err);
            });
        return new Promise((resolve) => {
          resolve();
        });
        },

    },
  }
</script>

<style scoped>
  .logo {
    width: 30%;
  }
  .transparent {
    background-color: transparent;
  }
  .row-pointer {
  cursor: pointer;
  }
  .share-url {
    font-size: 12px;
  }
  .center-block {
  top:30%;
  left: 50%;
  transform: translate3d(-50%,-50%, 0);
  position: absolute;
  }
  .content-center {
    text-align:center;
  }
  .image-icon {
    float:left;
    cursor: pointer;
  }
  .menu {
     cursor: pointer;
     font-size: 1.2rem !important;
  }
  .selected {
     font-weight: bold;
  }
  .row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {  
  cursor: pointer;
}
</style>
