<template>
  <v-app>
    
    <v-app-bar app elevation="0">
      <v-spacer />
      <v-toolbar-title class="menu" @click="gotoApp()">App</v-toolbar-title>
      <v-toolbar-title class="grey--text">&nbsp;|&nbsp;</v-toolbar-title>
      <v-toolbar-title @click="$router.push('/about')" class="menu d-inline-flex">About</v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    
   <v-main class="grey lighten-4 pt-0 pl-0">
      <div style="text-align:center">
      <h2>MyTrustedCloud.io</h2>
      <p>Experience web3 decentralized cloud storage</p>     
      <a href="https://mytrustedcloud.io"><img width="50" class="center" :src="require('@/assets/logomtc4.png')"></a>
      </div> 
    <v-container>
      <v-row justify="center" no-gutters >
        <v-col md="6">
          <div>
            <h1>Your profile</h1>
            <h2 class="indigo--text"><br>{{ $auth.user.name }}</h2>
            <p>{{ $auth.user.email }}<br></p>
            <br><h3>Subscription and quota</h3>
            <p>Subscription: {{$auth.user["https://www.mytrustedcloud.io/subscription"]}}
            <br>Storage capacity: {{maxstorage_convert}}
            <br>Upload file size limit: {{maxupload_convert}}</p>
        </div>
        </v-col>
      </v-row>
    </v-container>
    </v-main>

      <v-footer padless>
        <v-col class="text-center" cols="12">
          2022 — <strong>MyTrustedCloud.io</strong> [Alpha]
        </v-col>
    </v-footer>
    
  </v-app>
</template>
<script>
export default {

  data() {
      return {
          maxstorage_convert:'',
          maxupload_convert:'',
      }
    },

  

  created: function () {
    this.maxstorage_convert = this.FileConvertSize(this.$auth.user["https://www.mytrustedcloud.io/maxStorage"]);
    this.maxupload_convert = this.FileConvertSize(this.$auth.user["https://www.mytrustedcloud.io/maxUpload"]);
    },

  methods: {
    gotoApp() {
      window.location.href = process.env.VUE_APP_FRONTEND;
    },

    //File conversion octets
      FileConvertSize(aSize){
      aSize = Math.abs(parseInt(aSize, 10));
      var def = [[1, 'octets'], [1000, 'kb'], [1000*1000, 'Mb'], [1000*1000*1000, 'Gb'], [1000*1000*1000*1000, 'Tb']];
      for(var i=0; i<def.length; i++){
        if(aSize<def[i][0]) return (aSize/def[i-1][0]).toFixed(2)+' '+def[i-1][1];
      }
      },
  }
}
</script>

<style scoped>
  .menu {
     cursor: pointer;
     text-decoration:none;
     color: inherit;
     font-size: 1.2rem !important;
  }
  .link {
    text-decoration: none;
  }

</style>
