export default {
    doc: 'mdi-file-word blue--text text--darken-3',
    DOC: 'mdi-file-word blue--text text--darken-3',
    docx: 'mdi-file-word blue--text text--darken-3',
    DOCX: 'mdi-file-word blue--text text--darken-3',
    
    xls: 'mdi-file-excel green--text text--darken-3',
    XLS: 'mdi-file-excel green--text text--darken-3',
    xlsx: 'mdi-file-excel green--text text--darken-3',
    XLSX: 'mdi-file-excel green--text text--darken-3',
    csv: 'mdi-file-excel green--text text--darken-3',
    CSV: 'mdi-file-excel green--text text--darken-3',
    
    ppt: 'mdi-file-powerpoint red--text text--darken-1',
    PPT: 'mdi-file-powerpoint red--text text--darken-1',
    pptx: 'mdi-file-powerpoint red--text text--darken-1',
    PPTX: 'mdi-file-powerpoint red--text text--darken-1',
    
    jpg: 'mdi-file-image teal--text',
    JPG: 'mdi-file-image teal--text',
    bmp: 'mdi-file-image teal--text',
    BMP: 'mdi-file-image teal--text',
    gif: 'mdi-file-image teal--text',
    GIF: 'mdi-file-image teal--text',
    ico: 'mdi-file-image teal--text',
    ICO: 'mdi-file-image teal--text',
    jpeg: 'mdi-file-image teal--text',
    JPEG: 'mdi-file-image teal--text',
    png: 'mdi-file-image teal--text',
    PNG: 'mdi-file-image teal--text',
    svg: 'mdi-file-image teal--text',
    SVG: 'mdi-file-image teal--text',
    tif: 'mdi-file-image teal--text',
    TIF: 'mdi-file-image teal--text',
    tiff: 'mdi-file-image teal--text',
    TIFF: 'mdi-file-image teal--text',
    webp: 'mdi-file-image teal--text',
    WEBP: 'mdi-file-image teal--text', 

    aac: 'mdi-file-music blue--text text--darken-1', 
    AAC: 'mdi-file-music blue--text text--darken-1', 
    mid: 'mdi-file-music blue--text text--darken-1', 
    MID: 'mdi-file-music blue--text text--darken-1', 
    midi: 'mdi-file-music blue--text text--darken-1', 
    MIDI: 'mdi-file-music blue--text text--darken-1', 
    mp3: 'mdi-file-music blue--text text--darken-1',
    MP3: 'mdi-file-music blue--text text--darken-1',
    oga: 'mdi-file-music blue--text text--darken-1', 
    OGA: 'mdi-file-music blue--text text--darken-1', 
    wav: 'mdi-file-music blue--text text--darken-1', 
    WAV: 'mdi-file-music blue--text text--darken-1', 
    weba: 'mdi-file-music blue--text text--darken-1', 
    WEBA: 'mdi-file-music blue--text text--darken-1',

    avi:'mdi-file-video orange--text text--darken-1',
    AVI: 'mdi-file-video orange--text text--darken-1',
    mpeg: 'mdi-file-video orange--text text--darken-1',
    MPEG: 'mdi-file-video orange--text text--darken-1',
    mp4: 'mdi-file-video orange--text text--darken-1',
    MP4: 'mdi-file-video orange--text text--darken-1',
    ogv: 'mdi-file-video orange--text text--darken-1',
    OGV: 'mdi-file-video orange--text text--darken-1',
    webm: 'mdi-file-video orange--text text--darken-1',
    WEBM: 'mdi-file-video orange--text text--darken-1',

    zip: 'mdi-folder-zip yellow--text text--darken-2',
    ZIP: 'mdi-folder-zip yellow--text text--darken-2',
    rar: 'mdi-folder-zip yellow--text text--darken-2',
    RAR: 'mdi-folder-zip yellow--text text--darken-2',
    tar: 'mdi-folder-zip yellow--text text--darken-2',
    TAR: 'mdi-folder-zip yellow--text text--darken-2',

    pdf: 'mdi-file-pdf-box red--text text--darken-1',
    PDF: 'mdi-file-pdf-box red--text text--darken-1',
    
    sh: 'mdi-powershell blue--text text--darken-4',
    SH: 'mdi-powershell blue--text text--darken-4',

    html: 'mdi-language-html5 red--text text--darken-2',
    HTML: 'mdi-language-html5 red--text text--darken-2',
    xhtml: 'mdi-language-html5 red--text text--darken-2',
    XHTML: 'mdi-language-html5 red--text text--darken-2',
    js: 'mdi-nodejs yellow--text text--darken-2',
    JS: 'mdi-nodejs yellow--text text--darken-2',
    css: 'mdi-language-css3 blue--text text--darken-3',
    CSS: 'mdi-language-css3 blue--text text--darken-3',
    json: 'mdi-code-json',
    JSON: 'mdi-code-json',

    md: 'mdi-language-markdown',
    
    
    txt: 'mdi-file-document-outline',
    TXT: 'mdi-file-document-outline',
    rtf: 'mdi-file-document-outline',
    RTF: 'mdi-file-document-outline',

    go: 'mdi-language-go blue--text',
}
